<template>
  <AlertBox type="warning" :messages="responseError" />
  <SetLoader v-if="!calendarPageRequestFinish && !responseError.length" />
  <div v-else>
    <InstantFilterModal
      :pageData="listData"
      v-if="
        listData && listData.filter && listData.filter.isInstantFilterActive
      "
    />
    <FullCalendar
      :self="this"
      :events="calendarEvents"
      :resources="calendarResources"
      :calendar="calendar"
      :fields="fields"
    />
    <CalendarHiddenFields :calendar="calendar" />
  </div>
</template>
<script>
import InstantFilterModal from "@/components/custom/set-pages/InstantFilterModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FullCalendar from "@/components/full-calendar/FullCalendar.vue";
import CalendarHiddenFields from "@/components/set-design/form-items/CalendarHiddenFields.vue";
export default {
  name: "CALENDAR",
  props: ["key", "calendarId", "filterId"],
  data() {
    return {
      calendarEvents: [],
      responseError: [],
      calendar: {},
      listData: {},
      fields: [],
      calendarItems: [],
      calendarResources: [],
      calendarPageLayout: {},
      pageData: {},
      calendarDesignedItems: [],
      calendarPageRequestFinish: false,
    };
  },
  components: {
    FullCalendar,
    CalendarHiddenFields,
    InstantFilterModal,
  },
  watch: {
    "$route.params.key"() {
      if (this.$route.name === "calendar" && this.calendarPageRequestFinish) {
        this.getCalendarPage();
      }
    },
    "$route.params.calendarId"() {
      if (this.$route.name === "calendar" && this.calendarPageRequestFinish) {
        this.getCalendarPage();
      }
    },
    "$route.query.filterId"() {
      if (this.$route.name === "calendar" && this.calendarPageRequestFinish) {
        this.getCalendarPage();
      }
    },
  },
  methods: {
    defaultProperties() {
      this.responseError = [];
      this.listData = {};
      this.fields = [];
      this.calendar = {};
      this.calendarItems = [];
      this.calendarEvents = [];
      this.pageData = {};
      this.calendarDesignedItems = [];
      this.calendarPageRequestFinish = false;
    },
    getCalendarEvents(key, filterId = "", dataFetchSize = 500) {
      this.$appAxios
        .get(
          `rws-SetController-List?key=${key}&filterId=${
            String.isNullOrWhiteSpace(filterId) ? "" : filterId
          }&page=1&size=${dataFetchSize}&orderFields=&ordering=&isAsc=false`
        )
        .then((response) => {
          this.calendarPageRequestFinish = true;
          var result = response.data;
          this.listData = result;
          this.$store.commit("setPageDto", {
            ...this.listData,
            pageDetail: this.calendar,
          });
          if (result.isOk) {
            this.$store.commit("setPageLayoutAvailable", true);
            this.fields = result.filter.visibleFields;
            if (result.filter.hiddenFields.length) {
              result.filter.hiddenFields.forEach((element) => {
                this.fields.push(element);
              });
            }

            this.calendarItems = result.pagedItems.items;
            this.calendarEvents = this.$root.calendarDataChange(
              result.pagedItems.items,
              this.calendar,
              this.fields
            );
            this.calendarResources = this.$root.calendarDataChange(
              result.pagedItems.items,
              this.calendar,
              this.fields,
              true
            );
          } else {
            this.responseError.push(result.msg);
          }

          var title = this.$t(
            "Set.CalendarFormat",
            {},
            { locale: this.$store.state.activeLang }
          ).replaceAll("OBJECT_NAME", this.calendar.name);
          setCurrentPageBreadcrumbs(title, [
            {
              name: this.listData.customObject.pluralName,
              isRouteLink: true,
              html: String.format("/set/list/{0}", this.$route.params.key),
            },
            {
              name: title,
            },
          ]);

          this.$root.changeDocumentTitle(title);
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    getCalendarPage() {
      setCurrentPageBreadcrumbs("", [{ name: "HELP" }]);
      this.defaultProperties();
      // this.$root.getSetScripts(this.$route.params.key, "Calendar").then(() => {
      this.pageProcess();
      // });
    },
    pageProcess() {
      var key = this.$route.params.key,
        calendarId = this.$route.params.calendarId,
        filterId = this.$route.query.filterId;

      if (
        String.isNullOrWhiteSpace(key) ||
        String.isNullOrWhiteSpace(calendarId)
      ) {
        this.$router.push(this.$appHomeUrl);
        return;
      }

      var calendarPageRequestUrl = String.format(
        "rws-GetCalendarView?publicId={0}",
        calendarId
      );

      this.$appAxios
        .get(calendarPageRequestUrl)
        .then((response) => {
          this.calendar = response.data;
          if (!this.calendar.isOk) {
            this.$router.push({
              name: "PermissionNone",
              params: { key: key },
            });
          } else {
            this.$root.getSetScripts(this.$route.params.key, "Calendar");
            this.getCalendarEvents(key, filterId, this.calendar.dataFetchSize);
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
  },
  mounted() {
    this.getCalendarPage();
  },
};
</script>
