<template>
  <input
    type="hidden"
    id="CustomObjectPublicId"
    :value="calendar.customObjectPublicId"
  />
  <input type="hidden" id="CustomObjectKey" :value="$route.params.key" />
  <input
    type="hidden"
    id="ViewFilterPublicId"
    name="Filter"
    :value="$route.query.filterId"
  />
  <input
    type="hidden"
    id="CalendarPublicId"
    name="CalendarPublicId"
    :value="calendar.publicId"
  />
  <input
    type="hidden"
    id="IsInsertOnCalendar"
    name="IsInsertOnCalendar"
    :value="calendar.isInsertOnCalendar"
  />
  <input
    type="hidden"
    id="IsEditableOnCalendar"
    name="IsEditableOnCalendar"
    :value="calendar.isEditableOnCalendar"
  />
  <input
    type="hidden"
    id="StartDateFieldPublicId"
    name="StartDateFieldPublicId"
    :value="calendar.startDateFieldPublicId"
  />
  <input
    type="hidden"
    id="EndDateFieldPublicId"
    name="EndDateFieldPublicId"
    :value="calendar.endDateFieldPublicId"
  />
  <input
    type="hidden"
    id="TimelineFieldPublicId"
    name="TimelineFieldPublicId"
    :value="calendar.timelineFieldPublicId"
  />
  <input
    type="hidden"
    id="PageLayoutPublicId"
    name="PageLayoutPublicId"
    :value="calendar.pageDesignerPublicId"
  />
  <input type="hidden" id="StartDateValue" name="StartDateValue" value="" />
  <input type="hidden" id="EndDateValue" name="EndDateValue" value="" />
  <input
    type="hidden"
    id="TimelineFieldValuePublicId"
    name="TimelineFieldValuePublicId"
    value=""
  />
  <input
    type="hidden"
    id="TimelineFieldValue"
    name="TimelineFieldValue"
    value=""
  />
  <input
    type="hidden"
    id="TitleFieldPublicIds"
    name="TitleFieldPublicIds"
    :value="calendar.titleFieldPublicIds"
  />
  <input
    type="hidden"
    id="DetailUrl"
    name="DetailUrl"
    :value="String.format('/set/detail/{0}/', $route.params.key)"
  />
</template>
<script>
export default {
  name: "CalendarHiddenFields",
  props: ["calendar"],
};
</script>
