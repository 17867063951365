<template>
  <div
    id="fullCalendarEventModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-hidden="true"
  >
    <div
      class="modal-dialog sx-modal-responsive"
      :style="{
        width: String.format('{0}%', calendarInfo.lightBoxRatio),
        'min-width': String.format('{0}%', calendarInfo.lightBoxRatio),
      }"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="designedItems.length">
            <i class="far fa-calendar-alt"></i>&nbsp;
            <template v-if="crudType === 'new'">
              {{
                this.$t(
                  "Set.NewFormat",
                  {},
                  { locale: this.$store.state.activeLang }
                ).replaceAll("OBJECT_NAME", this.pageData.name)
              }}
            </template>
            <template v-else>
              {{
                String.format(
                  "{0} {1}",
                  pageData.name,
                  $t("Set.Edit", {}, { locale: this.$store.state.activeLang })
                )
              }}
            </template>
          </h5>
          <h5 v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" :class="{ 'p-0': designedItems.length }">
          <SetLoader
            :isDocumentTitleChange="false"
            v-if="!designedItems.length"
          />
          <form class="form w-100" id="calendarForm">
            <AlertBox type="warning" :messages="formError" />
            <HiddenFields crudType="Calendar" :pageData="pageData" />
            <DesignItem
              v-for="element in designedItems"
              :key="element.id"
              :element="element"
              :items="designedItems"
            />
          </form>
        </div>
        <div class="modal-footer">
          <template v-if="designedItems.length">
            <template v-if="crudType === 'new'">
              <button
                @click="saveEvent('btn-calendar-event-save')"
                type="button"
                class="btn btn-success btn-calendar-event-save me-2"
              >
                <span>
                  <i class="fa fa-save"></i>
                  {{
                    $t(
                      "BaseModelFields.Save",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</span
                >
              </button>
            </template>
            <template v-else>
              <button
                @click="updateEvent('btn-calendar-event-edit')"
                type="button"
                class="btn btn-warning btn-calendar-event-edit me-2"
              >
                <span>
                  <i class="fa fa-edit"></i>
                  {{
                    $t("Set.Edit", {}, { locale: this.$store.state.activeLang })
                  }}</span
                >
              </button>
            </template>
          </template>
          <button
            type="button"
            class="btn btn-danger btn-calendar-modal-close"
            data-bs-dismiss="modal"
          >
            <i class="fa fa-times"></i>
            {{
              $t(
                "BaseModelFields.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
import HiddenFields from "@/components/set-design/form-items/HiddenFields.vue";
import DesignItem from "@/components/set-design/DesignItem.vue";
export default {
  name: "EventModal",
  props: ["pageData", "designedItems", "crudType", "calendarInfo"],
  data() {
    return {
      model: {},
      formError: [],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "BaseModelFields.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses:
        ".btn-calendar-event-save, .btn-calendar-event-edit",
    };
  },
  components: {
    DesignItem,
    HiddenFields,
  },
  methods: {
    saveEvent(buttonName) {
      var form = $("form#calendarForm");
      form.addClass("was-validated");
      if (!form[0].checkValidity() || !this.$root.forceFormValidation(form)) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses),
        self = this;

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.formError = [];
      this.model = this.$root.modelCreate("form#calendarForm");
      this.$appAxios
        .post("/rws-SetController-New", this.model)
        .then((response) => {
          var result = response.data;

          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          form.removeClass("was-validated");

          if (result.isOk) {
            createToast(
              this.$t(
                "BaseModelFields.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );

            this.$emit("addEvent", this.model, result);
            $(".btn-calendar-modal-close").trigger("click");
          } else {
            //todo: warnings and info messages
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            if (result.errors.length > 0) {
              result.errors.forEach((element) => {
                self.formError.push(
                  String.format("{0}: {1}", element.fieldName, element.message)
                );
              });
            }

            self.formError.push(result.message);

            createToast(
              this.$t(
                "BaseModelFields.ErrorMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );
          }
        })
        .catch(function (error) {
          // alert(error);
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          self.formError.push(error);
        });
    },
    updateEvent(buttonName) {
      var form = $("form#calendarForm");
      form.addClass("was-validated");
      if (!form[0].checkValidity() || !this.$root.forceFormValidation(form)) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses),
        self = this;

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.formError = [];
      this.model = this.$root.modelCreate("form#calendarForm");
      this.$appAxios
        .post("/rws-SetController-Edit", this.model)
        .then((response) => {
          var result = response.data;

          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          form.removeClass("was-validated");

          if (result.isOk) {
            createToast(
              this.$t(
                "BaseModelFields.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );

            this.$emit("updateEvent", this.model, result);
            $(".btn-calendar-modal-close").trigger("click");
          } else {
            //todo: warnings and info messages
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            if (result.errors.length > 0) {
              result.errors.forEach((element) => {
                self.formError.push(
                  String.format("{0}: {1}", element.fieldName, element.message)
                );
              });
            }

            self.formError.push(result.message);

            createToast(
              this.$t(
                "BaseModelFields.ErrorMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );
          }
        })
        .catch(function (error) {
          // alert(error);
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          self.formError.push(error);
        });
    },
  },
};
</script>
